.modal-information {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  padding: 0px 20px;
  overflow-y: auto;
}
.modal-information .container-information {
  position: fixed;
  max-width: 500px;
  max-height: 300px;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2rem;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.header-modal-information {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  width: 100%;
  height: 20%;
  background-color: #10233f;
  position: absolute;
  top: 0px;
  left: 0px;
}

.close {
  border: 0;
  background-color: transparent;
}

.header-modal-information span {
  font-size: 17px;
  color: #fff;
}

.text-information-content {
  margin-bottom: 20px;
  display: flex;
}

.text-information-content span {
  color: #646566;
  text-align: center;
}

.button-information {
  height: 35px;
  width: 100%;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 540px) {
  .modal-information .container-information {
    margin: 0px 20px;
  }
}
