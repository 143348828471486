.container--message-request-prize {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
}

.text-message--message-request-prize{
  text-align: center;
  color: #fff;
  max-width: 800px;
  margin: 20px 12px;
  margin-top: 20%;
}

.subtitle--message-request-prize {
	margin-top: 15px;
	color: rgb(255, 255, 255);
	text-align: center;
}
