.container-slade-doubts {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.single-box {
  height: 70px;
  border-radius: 7px;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.single-box-null {
  height: 70px;
  border-radius: 7px;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.text-doubts {
  margin-left: 10px;
}

.carousel-indicators {
  left: 50%;
  top: auto;
  bottom: -48px;
  display: flex;
  align-items: center;
  width: 200px;
}

.carousel-indicators button {
  background-color: #F0EBE6 !important;
  height: 5px !important;
}

.carousel-indicators .active {
  background-color: #005091 !important;
}

.content-carousel-control {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 10px;
}

.accordion-item-slide-doubts {
  margin: 20px 10px !important;
  background: #F0EBE6 !important;
}

.carousel-control-prev,
.carousel-control-next{
  z-index: 2;
  position: inherit;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #707070 !important;
  margin-right: 10px;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 0.8rem;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' fill='%707070' viewBox='0 0 256 512'><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z'/></svg>");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%707070' viewBox='0 0 320 512'><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z'/></svg>");
}

@media (max-width: 700px) {
  .carousel-indicators {
    left: 0;
    bottom: -48px;
  }
}