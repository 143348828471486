.container-businessInfo {
  background-color: #10233f;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.container-businessInfo h1 {
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.container-businessInfo p {
  color: #fff;
  text-align: center;
  max-width: 700px;
  margin: 0px 10px;
}

.main-business {
  margin-top: 0.7em;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  padding: 0px 10px;
}

.main-business label {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.main-business input {
  margin-bottom: 10px;
  border: 0;
  border-radius: 10px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
}

.button-customers-business {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 400px;
  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.content-radio-busuness-info {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.custom-radio-business-info input {
  display: none;
}

.custom-radio-business-info input + label:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 5px; 
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 3px;
  border-radius: 50%;
  cursor: pointer;
}

.custom-radio-business-info input:checked + label:before {
  background-color: #42baef;
  border: 4px solid #ffff;
}

.text-checkbox {
  padding-bottom: 10px;
  position: absolute;
  top: 3px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 7px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); 
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container-checkbox input:checked ~ .checkmark {
  background-color: #42baef;
}

.bottom-links {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 20px;
}

.bottom-links a {
  color: #fff;
  font-size: 0.7rem;
}

.text-link {
  color: #fff;
  font-size: 0.7rem;
}

.area-input-business {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 20px;
}

@media (max-width: 600px) {
  .main-business {
    flex-direction: column;
    padding: 0px;
  }
  .area-input-business {
    margin: 0px auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 550px) {
  .button-customers-business {
    margin-top: 60px;
  }
  .area-checkboxs label {
    margin-bottom: 2.5rem;
  }
}
