.container-header {
  display: flex;
  height: 70px;
  box-sizing: border-box;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding-left: 5%;
  padding-right: 5%;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99999999999999;
}
.margin-botton-header {
  margin-bottom: 90px;
}
.menu-header {
  display: flex;
  list-style: none;
  gap: 1.5rem;
}

.logo-header {
  width: 80px;
  margin-right: 20px;
}

.menu-header a {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
}

.menu-header li a {
  color: #696969;
  font-size: 16px;
}

.menu-header li a:hover {
  color: #005dad;
  font-size: 16px;
}

.header-button {
  color: #005dad;
  font-size: 16px;
  cursor: pointer;
}

.section {
  position: absolute;
  left: 110px;
}

.options {
  display: flex;
  align-items: center;
  font-weight: bold;
  border: transparent;
  background: transparent;
}

.section span {
  font-size: 1rem;
}

.options img {
  height: 10px;
  width: 10px;
  margin-left: 5px;
}

#btn-mobile {
  display: none;
}

@media (max-width: 820px) {
  .container-header {
    justify-content: space-between;
  }
  .menu-header {
    display: block;
    position: absolute;
    width: 100%;
    top: 70px;
    right: 0px;
    background: #f0f5fa;
    z-index: 1000;
    transition: 0.5s;
    height: 0px;
    visibility: hidden;
    overflow-y: hidden;
  }
  .nav-header {
    display: flex;
    align-items: center;
  }
  .nav-header.active .menu-header {
    height: calc(100vh - 70px);
    visibility: visible;
    overflow-y: auto;
  }

  .menu-header a {
    font-size: 23px;
    color: #025dad;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  #btn-mobile {
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    background: none;
  }
  #hamburger {
    color: #025dad;
    border-top: 2px solid;
    width: 20px;
  }
  #hamburger::after,
  #hamburger::before {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background: currentColor;
    margin-top: 5px;
    transition: 0.3s;
    position: relative;
  }
  .nav-header.active #hamburger {
    border-top: transparent;
  }

  .nav-header.active #hamburger::before {
    transform: rotate(135deg);
  }
  .nav-header.active #hamburger::after {
    transform: rotate(-135deg);
    top: -7px;
  }
}
