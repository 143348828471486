.container-request-prize {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background-color: #10233f;
  margin-top: -60px;
}

.label-option-product-request {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.container-request-prize h1 {
  color: #fff;
  text-align: center;
}

.container-request-prize h4 {
  color: #fff;
  margin-top: 20px;
}

.container-request-prize p {
  color: #fff;
  text-align: center;
  max-width: 700px;
  margin: 0px 10px;
}

.native-select-dental-request-prize {
  width: 60%;
  margin-bottom: 30px;
}

.input-code-number-request-prize {
  height: 38px;
  width: 90px;
  background: #fff;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  color: #005091;
  font-weight: bold;
  text-align: center;
}

.form-request-prize {
  margin-top: 3em;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 972px;
  padding: 0px 10px;
}

.area-form-requestprize {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 960px;
  width: 100%;
}

.select-prize--request-prize {
  width: 350px;
}

.area-form-requestprize label {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
  align-self: flex-start;
}

.button-request-prize {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.checkbox-text-prize {
  color: #fff;
  width: max-content;
}

.area-selected-prize-requeste {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.content-label-request-prince {
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.content-label-request-prince span {
  color: #fff;
  width: max-content;
  font-size: 12px;
}

.content-checkbox-area-request-prize {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.items-preview-request-prize {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.container-preview--request-prize {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 90px 250px 300px;
  align-self: center;
  gap: 10px;
} 

.label-preview--request-prize {
  color: rgb(255, 255, 255);
  width: max-content;
  font-size: 12px;
}

.item-preview--request-prize input{
  border-radius: 8px;
  border: 0;
  padding: 8px;
  width: 100%;
  color: #005091;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  background: #fff;
} 

@media (max-width: 850px) {
  .container-request-prize {
    height: 100%;
    margin-top: -45px;
  }

  .native-select-dental-request-prize {
    width: 100%;
  }

  .label-option-product-request {
    margin-left: 10px;
  } 
  
  .input-code-number-request-prize {
    width: 35%;
  }

  .input-code-number-tequest-prize {
    width: 40%;
  }

  .container-preview--request-prize {
    width: 100%;
    grid-template-columns: 100%;
  } 

  .container-preview--request-prize div:nth-child(1) {
    width: 25%;
  }

  .content-checkbox-area-request-prize {
    margin-top: 0px;
  }

  .content-input-cod-request-prize {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .select-prize--request-prize {
    width: 100%;
  }

  .area-selected-prize-requeste {
    flex-direction: column;
    align-items: flex-start;
  }

}


