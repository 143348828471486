.container-loading-external {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #10233f;
}

.container-loading-external h1 {
  text-align: center;
  color: #fff;
  margin: 0px 10px;
  margin-bottom: 20px;
}

.contente-loading-external {
  display: flex;
  flex-direction: column;
  align-items: center;
}
