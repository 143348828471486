.container-slade-information {
  width: 100%;
  max-width: 800px;
  /* display: flex; 
  justify-content: center; 
  align-items: center; */
  margin: 20px 0px;
}

.img-information {
  /* padding: 30px; */
  width: 100%;
}

.img-information img {
  max-width: 600px;
  margin-left: 3%;
  height: 400px;
  width: 100%;
  border-radius: 7px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .img-information img {
    height: 200px;
    width: 100%;
    border-radius: 7px;
    margin-left: 0;
  }
}

.single-box {
  height: 70px;
  border-radius: 7px;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.single-box-null {
  height: 70px;
  border-radius: 7px;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}

/* .carousel-indicators {
  left: 0;
  top: ;
  bottom: -50px;
  display: flex;
  align-items: center;
} 

.carousel-indicators button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  border: 1px solid #025dad !important;
} 

.carousel-indicators .active {
  background-color: #025dad;
} 
*/