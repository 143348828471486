.footer-home {
    width: 100%;
    height: 200px;
    background: #005091;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .content-links-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }

  .nav-footer {
    display: flex;
    align-items: center;
    margin-left: 50px;
    height: 100%;
  }

  .link-footer:hover {
    color: #50a5f0;
  }
  
  .nav-footer a {
    font-size: 0.8rem;
    color: #FFF;
    text-decoration: none;
    margin-right: 20px;
  }
  
  .area-links-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  

  .content-logo-footer img {
    border-radius: 15px;
    width: 100px;
    margin-right: 20px;
  }

  .iconArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 200px;
    margin-left: 10px;
    gap: 1rem;
  }
  
  .iconArea a {
    font-size: 23px;
  }

  .iconArea a svg{
    color: #FFF;
    transition: filter 0.2s;
  }

  .iconArea a svg:hover {
    filter: brightness(0.8)
  }

  .created-by-text {
    color: #FFF;
    font-size: 0.8rem;
  }

  .content-text-created-by{
    height: 100%;
    width: 200px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
  }

  @media (max-width: 600px) {
    .footer-home {
      height: 300px;
    }

    .content-links-footer {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .nav-footer {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
        margin-left: 10px
    }

    .iconArea {
        align-self: flex-end;
        margin-left: 0;
    }
 
    .iconArea a svg {
        font-size: 1.3rem;
    }

    .area-links-footer {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-left: 10px;
    }

    .content-text-created-by {
        height: 0;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 20px;
        width: 100%;    
    }

    .content-logo-footer img {
        border-radius: 15px;
        width: 70px;
        margin-top: 10px;
        margin-left: 0px;
    }

    .nav-footer a {
        margin-bottom: 10px;
    }

  }