.container-customersInfo {
  background-color: #10233f;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.container-customersInfo h1 {
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.container-customersInfo p {
  color: #fff;
  text-align: center;
  max-width: 700px;
  margin: 0px 10px;
}

.area-checkboxs {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 750px;
  padding: 0px 10px;
}

.area-checkboxs label {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.main-content-customers {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  padding: 0px 10px;
}

.main-content-customers label {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.main-content-customers input {
  margin-bottom: 10px;
  border: 0;
  border-radius: 10px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
}

.custom-select {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
}

.custom-select select {
  font-size: 0.9rem;
  border-radius: 10px;
  width: 100%;
  height: 35px;
  border: 0;
  background-color: #fff;
}

.button-customers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 400px;
  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* CHECKBOX  */
.container-checkbox {
  display: block;
  margin-top: 20px;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-checkbox {
  padding-bottom: 10px;
  position: absolute;
  top: 3px;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 7px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container-checkbox input:checked ~ .checkmark {
  background-color: #42baef;
}

.bottom-links {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 20px;
}

.bottom-links a {
  color: #fff;
  font-size: 0.7rem;
}

.text-link {
  color: #fff;
  font-size: 0.7rem;
}

.area-input-customers {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 20px;
}

@media (max-width: 600px) {
  .main-content-customers {
    flex-direction: column;
    padding: 0px;
  }
  .area-input-customers {
    margin: 0px auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 550px) {
  .button-customers {
    margin-top: 30px;
  }
  .area-checkboxs label {
    margin-bottom: 2.5rem;
  }
}
