.container-addProduct {
  background-color: #10233f;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.container-addProduct main {
  margin-top: 40px;
}

.container-addProduct h1 {
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.container-addProduct p {
  color: #fff;
  text-align: center;
  max-width: 700px;
}

.form-content {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  padding: 0px 10px;
}

.form-content label {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.form-content input {
  margin-bottom: 10px;
  border: 0;
  border-radius: 10px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
}

.input-add {
  background-color: red;
  padding: 10px;
  height: 35px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  right: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.form-content button {
  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.loadingAddProduct {
  display: flex;
  align-self: center;
  margin-top: 10px;
  width: 20px;
  height: 20px;
}

input[type="file"] {
  display: none;
}

.file-img-add {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  cursor: pointer;

  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 50%;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 600px) {
  .container-addProduct {
    height: 100%;
  }
}
