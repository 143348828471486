.container-give-prize {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: auto;
  background-color: #10233f;
  margin-top: -60px;
}

.container-give-prize h1, h2 {
  color: #fff;
}

.title-give-prize {
  margin-top: 20%;
}

.container-give-prize p {
  color: #fff;
  text-align: center;
  max-width: 700px;
  margin: 0px 10px;
}

.label-input-give {
  color: #fff;
  max-width: 400px;
  width: 100%;
}

.container-give-prize-input {
  width: 100%;
  margin-bottom: 10px;
  border: 0;
  border-radius: 10px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
  max-width: 400px;
}

.content-contact-client {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}

.content-input-cod-give-prize {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6px;
}

.label-preview--give-prize {
  color: rgb(255, 255, 255);
  width: max-content;
  font-size: 12px;
}

.select-prize--give-prize {
  width: 350px;
}

.content-checkbox-area-give-prize {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.item-contact-client {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  width: 100%;
  color: #fff;
}

.bold-label-give-prize {
  font-weight: bold;
  font-size: 15px;

}

.input-code-number-give-prize {
  border-radius: 8px;
  border: 0;
  padding: 8px;
  width: 115px;
  color: #005091;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  background: #fff;
}

.item-contact-client span {
  font-size: 14px;
}

.form-give-prize {
  /* margin-top: 3em; */
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  padding: 0px 10px;
}

.area-form-give-prize {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}

.area-input-give-prize {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0px 20px;
}

.area-form-give-prize-02 {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
}

.area-form-give-prize-02 p {
  color: white;
  text-align: center;
  margin-bottom: 30px;
}

.area-form-give-prize-02 label {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
  align-self: flex-start;
}

.area-selected-give-prize {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1px;
  gap: 5px;
}

.area-text-info-gize-prize {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.button-give-prize {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  /* margin-top: 30px; */
  margin-bottom: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.text-error-give-prize {
  color: red;
  font-size: 15px;
  text-align: center;
}

.area-selected-prize {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 500px) {
  .area-selected-give-prize {
    flex-direction: column;
  }

  .title-give-prize {
    margin-top: 10%;
  }

  .container-give-prize {
    margin-top: 0;
  }

  .container-give-prize h1, h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  .container-preview-prizes--give-prive {
    width: 100%;
  }

  .content-contact-client {
    flex-direction: column;
  }

  .select-prize--give-prize {
    width: 100%;
  }

  .content-checkbox-area-give-prize {
    margin-top: 0px;
  }
}
