.container-tutorial {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin: 20px auto; */
}

.banner-tutorial-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.banner-tutorial-content img {
  margin-bottom: 20px;
  height: 80%;
  width: 95%;
  max-width: 1100px;
  max-height: 650px;
  border-radius: 10px;
}

.container-tutorial > h1 {
  text-align: center;
  max-width: 550px;
  padding: 0 20px;

  font-size: 1.8rem;
  text-align: center;
  color: #005091;

  margin-bottom: 20px;
}

.content-tutotial-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  /* border: 1px solid #41baef; */
  border-radius: 10px;
  width: 75%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.item-tutoria {
  margin: 20px;
}

.item-tutoria > img {
  width: 455px;
  border-radius: 5px;
  margin-top: 15px;
}

.item-tutoria span {
  color: #005091;
  font-size: 1.1rem;
  margin-left: 5px;
  width: 100%;
}

.section-stapOne {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #10233f;
  height: 120px;
  width: 100%;
}

.button-open-tutorial {
  cursor: pointer;
  color: rgb(65, 186, 239);
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 20px;
}

.section-stapOne > h4 {
  color: #fff;
  max-width: 85%;
  text-align: center;
  font-size: 1.8rem;
}

.section-stapTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: #10233f;
  height: 120px;
  width: 100%;
}

.section-stapTwo > h4 {
  color: #fff;
  /* max-width: 85%; */
  width: 100%;
  font-size: 1rem;
  text-align: center;
}

.item-tutorial-request {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin: 20px;
}

.item-tutorial-request img {
  width: 455px;
  border-radius: 5px;
  margin-top: 15px;
}

.content-tutotial-request {
  border-radius: 10px;
  width: 75%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.textCircle {
  word-wrap: break-word;
  margin-left: 10px;
  overflow-y: auto;
  color: #005091;
}

.item-circle {
  display: flex;
  align-items: baseline;
}

.item-circle > h1 {
  font-size: 20px;
}

.border-gradient {
  background: linear-gradient(to bottom, #01b3ee, #51b257 );
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.areaCircle {
  background: #fff;
  border-radius: 25px;
  color: #005091;
  width: 46px;
  height: 46px;
  min-height: 46px;
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 800px) {
  .banner-tutorial-content img {
    margin: 10px 10px;
  }

  .content-tutotial-add {
    width: 95%;
  }

  .container-tutorial > h1 {
    font-size: 1.4rem;
  }

  .content-tutotial-request {
    width: 95%;
  }

  .section-stapOne > h4 {
    font-size: 1.4rem;
    max-width: 95%;
  }

  .section-stapTwo > h4 {
    font-size: 1rem;
  }

  .item-tutoria > img {
    width: 100%;
  }

  .item-tutorial-request img {
    width: 100%;
  }

  .item-tutoria {
    flex-direction: column;
  }
}
