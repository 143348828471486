.container-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin: 20px auto; */
}

.image_content {
  display: flex;
  justify-content: center;
}

.image_content img {
  margin: 0 20px;
  height: 80%;
  width: 95%;
  max-width: 1100px;
  max-height: 650px;
  border-radius: 10px;
}

.titleOffert {
  display: flex;
  justify-content: center;
  margin: 50px 10px;
  max-width: 1100px;
}

.titleOffert span {
  font-size: 3.3rem;
  text-align: center;
  color: #005091;
}

.lineDivider {
  margin: 20px 0;
  align-self: center;
  border: 1px solid #7bb354;
  width: 60px;
}

.content-boxTurorial {
  width: 100%;
  max-width: 800px;
}

.boxTutorialBlue {
  background-color: #42baef;
  margin: 10px;
  padding: 30px;
  border-radius: 10px;
  min-height: 120px;
}

.numberStep {
  background-color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  position: absolute;
  color: #42baef;
  font-weight: bold;
  font-size: 40px;
}

.numberStepDark {
  background-color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  position: absolute;
  color: #005091;
  font-weight: bold;
  font-size: 40px;
}

.boxTutorialDarkBlue {
  background-color: #005091;
  margin: 10px;
  padding: 30px;
  border-radius: 10px;
  min-height: 120px;
}

.numberStepDarkBlue {
  background-color: #fff;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  left: 10;
}

.numberStepDarkBlue span {
  color: #10233f;
  font-weight: bold;
  font-size: 40px;
}

.textStep {
  word-wrap: break-word;
  margin-left: 60px;
  overflow-y: auto;
}

.textStep span {
  color: #fff;
  font-size: 20px;
}

.timeOffert {
  display: flex;
  justify-content: center;
  margin: 50px 10px 0px 10px;
}

.timeOffert span {
  font-size: 1.3rem;
  text-align: center;
  color: #696969;
  max-width: 700px;
}

.moreOffert {
  display: flex;
  justify-content: center;
  margin: 10px 10px 50px 10px;
}

.moreOffert span {
  font-size: 1.3rem;
  text-align: center;
  color: #696969;
  max-width: 600px;
}

.subtitle-home {
  font-size: 2.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: justify;
  color: #005091;
}

.button-dentais {
  height: 40px;
  width: 290px;
  border-radius: 7px;
  border: 0;
  background-color: #69B42D;
  color: #fff;
  font-size: 16px;
  margin: 20px 10px 50px 10px;
}

.button-dentais button {
  height: 40px;
  width: 290px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin: 10px;
}

.button-area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: #10233f;
  width: 100%;
}

.area-login-home{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.area-login-home span {
  color: #fff;
  margin-bottom: 5px;
}

.content-button-home {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 600px;
  height: 150px;
}

.button-area button {
  height: 40px;
  width: 290px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin: 10px;
}

.app-triibo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
}

.are-app-triibo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.area-text-app {
  display: flex;
}

.app-triibo p {
  font-weight: 700;
  color: #696969;
}

.app-triibo img {
  width: 160px;
  height: 35px;
  margin: 5px;
}

.socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 210px;
  width: 100%;
  background-color: #eff5fa;
}

.socialMedia span {
  font-size: 1.5rem;
  color: #005091;
}

.container-box {
  display: flex;
  width: 100%;
  max-width: 800px;
}

.content-links a {
  color: #575757;
  text-decoration: none;
  margin-bottom: 10px;
}

.area-icon {
  display: none;
}

@media (max-width: 600px) {

  .titleOffert span {
    font-size: 1.8rem;
  }

  .timeOffert span {
    font-size: 1rem;
  }

  .button-area {
    height: 240px;
  }

  .moreOffert span {
    font-size: 1rem;
  }

  .subtitle-home {
    font-size: 1.4rem;
  }
  .footer-home {
    display: block;
  }
 
  .content-button-home {
    flex-direction: column;
  }
 
  .area-icon {
    display: flex;
    position: absolute;
    height: 30px;
    right: 20px;
    cursor: pointer;
  }

  .app-triibo {
    flex-direction: column;
  }

  .are-app-triibo {
    flex-direction: column;
    padding: 10px;
    max-width: 400px;
  }

  .app-triibo p {
    text-align: center;
  }

  .app-triibo img {
    margin-bottom: 10px;
  }
}

@media (max-width: 700px) {
  .container-box {
    display: flex;
    flex-direction: column;
  }
}

@keyframes navFooter {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}

.nav-footer .active-nav-links a {
  display: flex;
  animation-name: navFooter;
  animation-duration: 4s;
  color: #575757;
}

.moreOffert span a {
  color: #005091;
}

.btn-pdf:hover {
  color: #575757;
}
