.button-open-modal-dentais {
  height: 40px;
  width: 290px;
  border-radius: 7px;
  border: 0;
  background-color: #69B42D;
  color: #fff;
  font-size: 16px;
  margin: 10px;
}
