.container-form-additional-info {
	display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  background-color: #10233f;
}

.content-text-form-adicional-info {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.container-form-additional-info h1 {
  color: #fff;
}

.container-form-additional-info p {
  color: #fff;
  text-align: center;
  max-width: 700px;
  margin: 0px 10px;
}

.content-form-additional-info {
	width: 100%;
	margin: 30px 0;
	max-width: 500px;
	padding: 0 50px;
	display: flex;
	flex-direction: column;
} 

.content-form-additional-info label {
	color: #fff;
	font-size: 0.7rem;
	margin-bottom: 0.2rem;
}

.content-radio-form-additional-info {
  display: flex;
  flex-direction: row;
  gap: 10px;
	margin-bottom: 30px;
}

.custom-radio-form-additional-info input {
  display: none;
}

.custom-radio-form-additional-info input + label:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 5px; 
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 3px;
  border-radius: 50%;
  cursor: pointer;
}

.custom-radio-form-additional-info input:checked + label:before {
  background-color: #42baef;
  border: 4px solid #ffff;
}

@media (max-width: 500px) {
  .container-form-additional-info {
    margin-top: 100px;
  }
}	