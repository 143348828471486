.container-information {
  background-color: #10233f;
  height: 100%;
  display: flex;
  align-items: center;
}

.container-information main {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin: 0px auto;
}

.title-information {
  margin: 20px 0px;
  color: #fff;
}

.title-info {
  color: #42baef;
}
.main-infomation h1 {
  /* color: #fff; */
  text-align: center;
}

.main-infomation p {
  text-align: center;
  color: #fff;
}

.area-btn {
  width: 60%;
  margin: 0px auto;
  margin-bottom: 40px;
  max-width: 350px;
}

.btn-information {
  width: 100%;
  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.img-area {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.img-area img {
  height: 60px;
}

.text-botton {
  text-align: left;
}

/* Control Carousel */
.content-buttons-carousel {
 display: flex;
 /* flex-direction: row; */
 /* justify-content: center; */
 /* align-items: center; */
}

.btn-control-carousel {
  background-color: #10233f;
  border: 0;
}

.next-carousel{
  width: 60px;
  background-color: #42baef;
  padding: 10px;
  /* border-radius: 50%; */

  transition: filter 0.3s;

}

.next-carousel:hover {
  filter: brightness(0.7)

}

.prev-carousel {
  width: 60px;
  background-color: #42baef;
  padding: 10px;
  /* border-radius: 50%; */

  transition: filter 0.3s;

}

.prev-carousel:hover {
  filter: brightness(0.7)

}

@media (max-width: 700px) {
  .carousel-indicators {
    left: 0;
    bottom: -48px;
  }
}