.modal-login {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  padding: 0px 20px;
  overflow-y: auto;
}
.modal-login .container-login {
  position: fixed;
  max-width: 500px;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2rem;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.header-modal-login {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  width: 100%;
  height: 15%;
  background-color: #10233f;
  position: absolute;
  top: 0px;
  left: 0px;
}

.title-modal-sign-up {
  text-align: center;
  font-size: 17px; 
  color: #696969;
  margin-top: 30px;
  margin-bottom: 0.8rem;
}

.close {
  border: 0;
  background-color: transparent;
}

.header-modal-login span {
  font-size: 17px;
  color: #fff;
}

.title-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #646566;
}

.form-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-login label {
  text-align: start;
  font-size: 14px;
  color: #025dad;
  margin-bottom: 0.2rem;
}

.form-login input {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #646566;
  height: 35px;
  margin-bottom: 1rem;
}

.button-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100%;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.button-login-link {
  color: #fff;
  height: 35px;
  width: 100%;
  border-radius: 7px;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
}

.button-login-link a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button-nav-login {
  margin-top: 0.3rem;
  color: #646566;
  border: 0;
  background-color: transparent;
  text-align: left;
}

.button-nav-login:hover {
  color: #42baef;
}

a {
  color: #fff;
}
/* link que foi visitado */
a:visited {
  color: #fff;
}
/* quando o ponteiro do mouse passa no link */
a:hover {
  color: #fff;
}
/* quando o link for selecionado */
a:active {
  color: #fff;
}

@media (max-width: 540px) {
  .modal-login .container-login {
    margin: 0px 20px;
  }
}
