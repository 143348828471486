* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Uniform-pro';
  src: url('./assets/fonts/UniformPro-Reg.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

*,
button, input, span, h1, h2, h3, h4, h5, h6, p, a {
  font-family: 'Uniform-pro', Arial, Helvetica, sans-serif;
  /* font-family: "Arial", "Sans-Serif"; */
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}

*:focus {
  outline: 0;
}

body {
  font: 14px;
  background-color: #ffff;
}

a {
  /* text-decoration: none; */
  color: #000;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}
