.container-signUp {
  background-color: #10233f;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}

.container-signUp h1 {
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.container-signUp p {
  color: #fff;
  text-align: center;
  max-width: 700px;
  margin: 0px 10px;
}

.main-content {
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  padding: 0px 10px;
}

.main-content label {
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.main-content input {
  margin-bottom: 10px;
  border: 0;
  border-radius: 10px;
  height: 40px;
  text-align: center;
  font-size: 1rem;
}

.custom-select {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
}

.custom-select select {
  font-size: 0.9rem;
  border-radius: 10px;
  width: 100%;
  height: 35px;
  border: 0;
  background-color: #fff;
}

.main-content button {
  height: 35px;
  border-radius: 7px;
  border: 0;
  background-color: #42baef;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.area-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 20px;
}

@media (max-width: 600px) {
  .main-content {
    flex-direction: column;
    padding: 0px;
  }
  .area-input {
    margin: 0px auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .container-signUp {
    height: 100%;
  }
}
